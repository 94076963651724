import { useState } from "react"

export default function Slide({ content, displayCount }) {
    const borderThickness = displayCount === 4 ? 2 : 1

    const [cursor, setCursor] = useState('default')
    const toggleCursor = () => {
        cursor === 'default' ? setCursor('pointer') : setCursor('default') 
    }

    return (
        <div
            onMouseEnter={toggleCursor} 
            onMouseLeave={toggleCursor}
            style={
                {
                    backgroundImage: `url(${content.thumbnail})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    border: `.${borderThickness}vw solid white`,
                    height: '100%',
                    width: '100%',
                    cursor: cursor
                }
            }>
        </div>
    )
}
