import { useEffect, useState } from 'react';
import './Genres.css'

function Genres({ getActiveGenreNames }) {
  const [activeGenreNames, setActiveGenreNames] = useState(['jazz', 'classical', 'world-music'])

  useEffect(() => {
    getActiveGenreNames(activeGenreNames)
  }, [])

  const clickHandler = (e) => {
    e.target.classList.toggle('active')

    let arr = activeGenreNames

    e.target.classList.contains('active')
      ? arr.push(e.target.getAttribute('id'))
      : arr.splice(arr.indexOf(e.target.getAttribute('id')), 1)

    setActiveGenreNames(arr)
    getActiveGenreNames(activeGenreNames)
  }

  return (
    <div className="genres">
      <div className="genre active" id="jazz" onClick={clickHandler}>JAZZ</div>
      <div className="genre active" id="classical" onClick={clickHandler}>CLASSICAL</div>
      <div className="genre active" id="world-music" onClick={clickHandler}>WORLD MUSIC</div>
      <div className="genre" id="lounge" onClick={clickHandler}>LOUNGE</div>
      <div className="genre" id="old-school" onClick={clickHandler}>OLD SCHOOL</div>
      <div className="genre" id="relaxing" onClick={clickHandler}>RELAXING</div>
    </div>
  )
}

export default Genres;

