import { useState } from 'react';
import VideoPlayer from './VideoPlayer'
import Genres from './Genres';
import LatestVideo from './LatestVideo';
import RelatedVideo from './RelatedVideo';

let tvData = [
  {
    "name": 'classical0',
    "thumbnail": 'https://www.thisiscolossal.com/wp-content/uploads/2015/10/aquarium-1.jpg',
    "link": 'https://vimeo.com/70650427',
    "genre": "classical",
    "related": [
      {
        "name": 'cr0',
        "thumbnail": 'https://www.thisiscolossal.com/wp-content/uploads/2015/10/aquarium-1.jpg',
        "link": 'https://vimeo.com/70650427'
      },
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'classical1',
    "thumbnail": 'https://theartsdesk.com/sites/default/files/images/stories/CLASSICAL/David_Nice/Stabat_Mater_Barbican_141.JPG',
    "link": 'https://www.youtube.com/watch?v=3mHi_Jvuf2g',
    "genre": "classical",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'classical2',
    "thumbnail": 'https://www.santalessandro.org/wp-content/uploads/2020/05/orchestraeuropeagiovanilepost-1136x672.jpg',
    "link": 'https://www.facebook.com/OrchestraGiovanilediRoma/videos/561977487753619/',
    "genre": "classical",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'classical3',
    "thumbnail": 'https://i1.wp.com/www.cutcommonmag.com/wp-content/uploads/2017/04/Mahler-9-MP.jpg?resize=678%2C381&ssl=1',
    "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8',
    "genre": "classical",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'classical4',
    "thumbnail": 'https://berlinomagazine.com/wp-content/uploads/2019/07/EUYO_C_Andrew_McCoy__1_.jpg',
    "link": 'https://www.facebook.com/TheEUYO/videos/504691980504724/',
    "genre": "classical",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'jazz1',
    "thumbnail": 'https://d2v9ipibika81v.cloudfront.net/uploads/sites/104/2017/04/D%C3%ADa-Internacional-del-Jazz-.png',
    "link": 'https://www.youtube.com/watch?v=wRemGWwVpp4',
    "genre": "jazz",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'jazz2',
    "thumbnail": 'https://assets.rebelmouse.io/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbWFnZSI6Imh0dHBzOi8vYXNzZXRzLnJibC5tcy8xNzQ1ODYwNS9vcmlnaW4uanBnIiwiZXhwaXJlc19hdCI6MTY2MTgwNjk5NH0.ouE2aDsW0XaL5a0899uZtF1jUgz5ZpvozSIW-gkTSKg/img.jpg',
    "link": 'https://www.youtube.com/watch?v=G7Gp9gzIHU8',
    "genre": "jazz",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'jazz3',
    "thumbnail": 'https://cdn2.jazztimes.com/2020/06/lakecia-benjamin-367x550.jpg',
    "link": 'https://www.youtube.com/watch?v=dgoYcnUzVh4',
    "genre": "jazz",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'lounge1',
    "thumbnail": 'https://m.media-amazon.com/images/I/81tK3z8jmtL._SS500_.jpg',
    "link": 'https://www.youtube.com/watch?v=EdVS5jNXoOA',
    "genre": "lounge",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'lounge2',
    "thumbnail": 'https://direct.rhapsody.com/imageserver/images/alb.202518567/500x500.jpg',
    "link": 'https://www.youtube.com/watch?v=5HZt790zIbg',
    "genre": "lounge",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'lounge3',
    "thumbnail": 'https://m.media-amazon.com/images/I/81TknkvUyhL._SS500_.jpg',
    "link": 'https://www.youtube.com/watch?v=jpv2tMJJuz0',
    "genre": "lounge",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'lounge4',
    "thumbnail": 'https://images-na.ssl-images-amazon.com/images/I/61izVCEbigL._SL1023_.jpg',
    "link": 'https://www.youtube.com/watch?v=aQ-5hn0W-Eo',
    "genre": "lounge",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'old-school1',
    "thumbnail": 'https://www.djmikebills.com/media/k2/items/cache/78a1f76203c562c3d7c318765865da44_M.jpg',
    "link": 'https://www.youtube.com/watch?v=f36T5839aLc',
    "genre": "old-school",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'old-school2',
    "thumbnail": 'https://direct.rhapsody.com/imageserver/images/alb.431428774/600x600.jpg',
    "link": 'https://www.youtube.com/watch?v=-C8TDEXgIQk',
    "genre": "old-school",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'relaxing1',
    "thumbnail": 'https://exploringyourmind.com/wp-content/uploads/2018/03/woman-listening-to-relaxing-music.jpg',
    "link": 'https://www.youtube.com/watch?v=AKy6Jx59fis',
    "genre": "relaxing",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'relaxing2',
    "thumbnail": 'https://i1.wp.com/mindfulnessmeditationinstitute.org/wp-content/uploads/2018/11/zen-background.jpg',
    "link": 'https://www.youtube.com/watch?v=hlWiI4xVXKY',
    "genre": "relaxing",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'relaxing3',
    "thumbnail": 'https://i1.sndcdn.com/artworks-Ab77AS6NZO57z7fs-i5kjCw-t500x500.jpg',
    "link": 'https://www.youtube.com/watch?v=1ZYbU82GVz4',
    "genre": "relaxing",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'world-music1',
    "thumbnail": 'https://www.afamilyontheroad.com/wp-content/uploads/2019/11/SOUND-OF-HUMANITY.jpg',
    "link": 'https://youtu.be/dyWIi7Tfh2o',
    "genre": "world-music",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'world-music2',
    "thumbnail": 'https://majoringinmusic.com/wp-content/uploads/2016/06/world-music-1-830x553.jpeg',
    "link": 'https://www.youtube.com/watch?v=DAhLXLj4UuE',
    "genre": "world-music",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'world-music3',
    "thumbnail": 'https://i.pinimg.com/originals/37/96/ac/3796ac68b8fc1e4656c1f8beb0d0424c.jpg',
    "link": 'https://www.youtube.com/watch?v=tp_y1B1eQeo',
    "genre": "world-music",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'world-music4',
    "thumbnail": 'https://cla.umn.edu/sites/cla.umn.edu/files/styles/feature_story_article/public/worldmusic_gamelan.jpg',
    "link": 'https://www.youtube.com/watch?v=l58dipKGTJE',
    "genre": "world-music",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'world-music5',
    "thumbnail": 'https://www.intrepidtravel.com/adventures/wp-content/uploads/2017/11/IMG_3309-e1511811683103.jpg',
    "link": 'https://www.youtube.com/watch?v=AzURQRdFW5Y',
    "genre": "world-music",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  },
  {
    "name": 'world-music6',
    "thumbnail": 'https://valenciaplaza.com/public/Image/2017/4/Cover-havana-WM-photo-by-Alex-Mene-7-1024x576_NoticiaAmpliada.jpg',
    "link": 'https://www.youtube.com/watch?v=ATvTt9jfZ2k',
    "genre": "world-music",
    "related": [
      {
        "name": 'cr1',
        "thumbnail": 'https://img1.10bestmedia.com/Images/Photos/349637/Teddy-Abrams--Iroquois--by-Frankie-Steele--3-_54_990x660.jpg',
        "link": 'https://www.youtube.com/watch?v=5VB1RvyfISM'
      },
      {
        "name": 'cr2',
        "thumbnail": 'https://news.wttw.com/sites/default/files/styles/json_lg/public/field/image/CSO_Review_HilaryHahn.jpg',
        "link": 'https://youtu.be/i0qjtJmQQCc'
      },
      {
        "name": 'cr3',
        "thumbnail": 'https://s3.amazonaws.com/sfc-datebook-wordpress/wp-content/uploads/sites/2/2019/05/66895898_DATEBOOK_california0507-1024x683.jpg',
        "link": 'https://www.youtube.com/watch?v=2ckqOukGKK8'
      },
      {
        "name": 'cr4',
        "thumbnail": 'https://img.theepochtimes.com/assets/uploads/2016/10/12/32ca8b742b.jpg',
        "link": 'https://www.youtube.com/watch?v=09BXkY5RhTM'
      },
      {
        "name": 'cr5',
        "thumbnail": 'https://media.npr.org/assets/img/2019/02/21/12440525_10153408517458269_8060462761400424604_o_wide-35a3790b33b8ec740998fcf132c852689f1ebe4d.jpg',
        "link": 'https://www.youtube.com/watch?v=cdyUhfU6Bj8'
      },
      {
        "name": 'cr6',
        "thumbnail": 'https://emtstatic.com/2019/01/iStock-490338206-696x465.jpg',
        "link": 'https://www.youtube.com/watch?v=YL-8zxvuuyE'
      },
      {
        "name": 'cr7',
        "thumbnail": 'https://customerthink.com/wp-content/uploads/29916238_m.jpg',
        "link": 'https://www.youtube.com/watch?v=iUgPEqwag3w'
      },
      {
        "name": 'cr8',
        "thumbnail": 'https://berkshireonstage.files.wordpress.com/2014/09/bosmalkki.jpg',
        "link": 'https://www.youtube.com/watch?v=LNWIKj54SFc'
      }
    ]
  }
]
const shuffledData = tvData.sort(() => Math.random() - 0.5)

const relatedVideoData = tvData[0].related

function Content() {
  const [activeGenresList, setActiveGenresList] = useState([])
  // console.log(activeGenresList)
  const [activeVideoURL, setActiveVideoURL] = useState('https://vimeo.com/70650427')

  const getActiveGenreNames = (genres) => {
    let arr = genres.map(genre => shuffledData.filter(data => data.genre === genre))
    let activeGenresData = []
    arr.forEach(genreGroup => {
      genreGroup.forEach(genreData => activeGenresData.push(genreData))
    })
    let shuffledActiveGenresData = activeGenresData.sort(() => Math.random() - 0.5)

    setActiveGenresList(shuffledActiveGenresData)
  }

  const slideClickHandler = (data) => {
    // console.log(data)
    setActiveVideoURL(data.link)
  }

  return (
    <>
      <VideoPlayer videoURL={activeVideoURL} />
      <Genres getActiveGenreNames={getActiveGenreNames} />
      <LatestVideo tvData={activeGenresList} displayCount={4} slideClickHandler={slideClickHandler} />
      <RelatedVideo tvData={relatedVideoData} displayCount={6} slideClickHandler={slideClickHandler} />
    </>
  )
}

export default Content;