import './VideoPlayer.css'
import ReactPlayer from 'react-player'

export default function VideoPlayer({ videoURL }) {

  return (
    <div className='player-wrapper'>
      <ReactPlayer
        className='react-player'
        controls
        url={videoURL}
        width='100%'
        height='100%'
      />
    </div>
  )

}