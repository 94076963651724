import arrowLeft from "./icons/arrow-left.svg"
import arrowRight from "./icons/arrow-right.svg"

export default function Arrow({direction, handleClick}) {
  let xOffset = direction === 'back' ? '-4%' : '101%'
  let arrowSize = window.innerWidth / 1000 * 24

  return (
    <div 
    className="Arrow" 
    onClick={handleClick}
    style={{
      border: '0.1vw solid #B456FB',
      borderRadius: '12%',
      cursor: 'pointer',
      display: "flex",
      alignItems: 'center',
      justifyContent: "center",
      position: "absolute",
      width: arrowSize,
      height: arrowSize,
      top: `calc(100%/2 - ${arrowSize/2}px)`,
      left: xOffset,
      overflow: 'hidden',
      zIndex: '1'
    }}
    >
      {direction === 'back' ? <img src={arrowLeft} alt="Left Arrow" style={{width: '40%'}} />  : <img src={arrowRight} alt="Right Arrow" style={{width: '40%'}} />}
    </div>
  );
}