import './Header.css'
import logo from './icons/logo.png'
import searchIcon from './icons/searchicon.png'

function Header() {
    return (
      <div className="header">
          <img src={logo} alt="Memo Land TV logo" id="site-logo" />
          <div className="search-wrapper">
            <input type="search" id="site-search" placeholder="Search" name="q"></input>
            <div id="search-icon">
                <img src={searchIcon} alt="search icon" id="search-icon-img" />
            </div>
          </div>
      </div>
    );
  }
  
  export default Header;
  