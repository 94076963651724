import { useState } from 'react'
import SliderContent from './SliderContent'
import Arrow from './Arrow'

export default function Slider({ displayCount, width, tvData, slidesGroupsCount, slideClickHandler }) {
  const step = 100 / slidesGroupsCount
  const [activeTranslateState, setActiveTranslateState] = useState([0, 0])

  const sCWWidth = displayCount === 4 ? 10.5 : 7

  const prevSlide = () => {
    if (activeTranslateState[0] === 0) {
      setActiveTranslateState([slidesGroupsCount - 1, (slidesGroupsCount - 1) * step])
    } else {
      setActiveTranslateState([activeTranslateState[0] - 1, (activeTranslateState[0] - 1) * step])
    }
  }

  const nextSlide = () => {
    if (activeTranslateState[0] === slidesGroupsCount - 1) {
      setActiveTranslateState([0, 0])
    } else {
      setActiveTranslateState([activeTranslateState[0] + 1, (activeTranslateState[0] + 1) * step])
    }
  }

  return (
    <div className="slider" style={{ position: 'relative' }}>
      <Arrow direction='back' handleClick={prevSlide} />
      <div className='slider-content-wrapper' style={{ height: `${sCWWidth}vw`, width: '100%', overflow: 'hidden' }}>
        <SliderContent
          displayCount={displayCount}
          width={width}
          translate={activeTranslateState[1]}
          tvData={tvData}
          slideClickHandler={slideClickHandler}
        />
      </div>
      <Arrow direction='forward' handleClick={nextSlide} />
    </div>
  );
}
