// import React from 'react';
import './App.css';
import Header from './app-main-content/Header'
import Content from './app-main-content/Content'

function App() {
  return (
    <div className="App">
      <div className="App-main-content">
        <Header />
        <Content />
      </div>
    </div>
  );
}

export default App;
