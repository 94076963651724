import Slide from "./Slide";
import "./Slides.css";

export default function Slides({ displayCount, tvData, slideClickHandler }) {
    const sWRightSpaceClass = displayCount === 4 ? 'slide-wrapper-for-four' : 'slide-wrapper-for-six'
    const sWWidth = displayCount === 4 ? 23.5 : 15.5

    const slidesGroups = []
    for (let groupIndex = 0; groupIndex < Math.ceil(tvData.length / displayCount); groupIndex++) {
        slidesGroups.push(
            <div
                key={groupIndex}
                style={
                    {
                        // backgroundColor: "yellow",
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                    }
                }
            >
                {
                    tvData.filter(
                        (data, index) => index >= groupIndex * displayCount && index < (groupIndex * displayCount) + displayCount
                    ).map(
                        (filteredData, i) =>
                            <div
                                className={sWRightSpaceClass}
                                key={i}
                                style={{
                                    // backgroundColor: "indigo", 
                                    width: `${sWWidth}%`,
                                    height: '100%'
                                }}
                                onClick={() => slideClickHandler(filteredData)}
                            >
                                <Slide content={filteredData} displayCount={displayCount} />
                            </div>
                    )
                }
            </div>
        )
    }

    return (
        <>
            {slidesGroups}
        </>
    )
}
