import './RelatedVideo.css'
import Slider from './Slider'

export default function RelatedVideo({ tvData, displayCount, slideClickHandler }) {
  let slidesGroupsCount = Math.ceil(tvData.length / displayCount)

  return (
    <div className="related-video">
      <h3>Related Video</h3>
      <Slider displayCount={displayCount} slidesGroupsCount={slidesGroupsCount} width={slidesGroupsCount * 100} tvData={tvData} slideClickHandler={slideClickHandler} />
    </div>
  );
}
