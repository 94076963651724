import Slides from './Slides';

export default function SliderContent({displayCount, width, translate, tvData, slideClickHandler}) {
    
    return (
        <div className='slider-content' 
            style={{
                transform: `translateX(-${translate}%)`,
                transition: `transform ease-in-out .5s`,
                height: '100%',
                width: `${width}%`,
                display: 'flex',
                // backgroundColor: 'red'
            }}
        >
            <Slides displayCount={displayCount} tvData={tvData} slideClickHandler={slideClickHandler} />
        </div>
    )
}
