import './LatestVideo.css'
import Slider from './Slider'

export default function LatestVideo({ tvData, displayCount, slideClickHandler}) {
  let slidesGroupsCount = Math.ceil(tvData.length / displayCount)
  
  return (
    <div className="latest-video">
      <h3>Latest Video</h3>
      <Slider displayCount={displayCount} slidesGroupsCount={slidesGroupsCount} width={slidesGroupsCount * 100} tvData={tvData} slideClickHandler={slideClickHandler} />
    </div>
  )
}
